const codes = [];

codes.push(/* js */`function printOne() {
    console.log("one");
}
printOne();`);

codes.push(/* js */`function printOne() {
    console.log("one");
}

function printTwo() {
    console.log("two");
}

setTimeout(printOne, 2000);
printTwo();`);

codes.push(/* js */`function printOne() {
    console.log("one");
}

function printTwo() {
    setTimeout(() => console.log("two"), 2000);
}

function printThree() {
    console.log("three");
}

printOne();
printTwo();
printThree();
`);

codes.push(/* js */`function printOne() {
    console.log("one");
}

async function printTwo() {
    return new Promise(resolve => {
        setTimeout(() => {
          console.log("two");
          resolve();
        }, 2000);
      });
}

function printThree() {
    console.log("three");
}

function printFour() {
    console.log("four");
}

printOne();
printTwo().then(() => printThree());
printFour();
`);

codes.push(/* js */`function printOne() {
  console.log("one");
}

async function printTwo() {
  return new Promise(resolve => {
      setTimeout(() => {
        console.log("two");
        resolve();
      }, 2000);
    });
}

function printThree() {
  console.log("three");
}

function printFour() {
  console.log("four");
}

async function start() {

  printOne();
  printTwo();
  printThree();
  printFour();

}

start();`);

codes.push(/* js */`function printOne() {
  console.log("one");
}

async function printTwo() {
  return new Promise(resolve => {
      setTimeout(() => {
        console.log("two");
        resolve();
      }, 2000);
    });
}

function printThree() {
  console.log("three");
}

function printFour() {
  console.log("four");
}

async function start() {

  printOne();
  await printTwo();
  printThree();
  printFour();

}

start();
`);

codes.push(/* js */`function printOne() {
  return new Promise(resolve => {
    setTimeout(() => {
      console.log("one");
      resolve();
    }, 1000);
  });
}

async function printTwo() {
  return new Promise(resolve => {
      setTimeout(() => {
        console.log("two");
        resolve();
      }, 500);
    });
}

function printThree() {
  return new Promise(resolve => {
    setTimeout(() => {
      console.log("three");
      resolve();
    }, 500);
  });
}

function printFour() {
  return new Promise(resolve => {
    setTimeout(() => {
      console.log("four");
      resolve();
    }, 1500);
  });
}

async function start() {
  printOne().then(printThree)
  await printTwo();
  printFour();
}

start();
  `);
  //codes.push(``);

export default codes;