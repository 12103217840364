import React from 'react';
import { useEffect } from 'react';

import './css/hljs.css';
import hljs from '../node_modules/highlight.js/lib/core.js';
import javascript from '../node_modules/highlight.js/lib/languages/javascript';
hljs.registerLanguage('javascript', javascript);

function Code(props) {
  useEffect(() => {
    hljs.initHighlightingOnLoad();
    document.querySelectorAll("pre code").forEach(block => {
      hljs.highlightBlock(block);
    });
  });

  return (
    <>
      <pre>
        <code>
            {props.code}
        </code>
      </pre>
    </>
  );
}

export default Code;
