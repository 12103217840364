import React from 'react';
import { useState, useEffect } from 'react';

import Code from './Code.js';
import RunCode from './RunCode.js';

import "./css/normalize.css";
import "./css/App.css";

import win from "./images/peanuts-celebration.webp";
import codes from "./quiz-data/quizData";

function createQuizData(codes) {
  let id = 0;
  return codes.map(code => {
    const question = {id: id, name: `Question ${id+1}`, done: false, unlocked: id === 0};
    id++;
    return question;
  })
}
const quiz = createQuizData(codes);

function App() {
  const [running, setRunning] = useState(false);
  const [guess, setGuess] = useState("");
  const [status, setStatus] = useState("");
  const [statusStyle, setStatusStyle] = useState("");
  const [currentId, setCurrentId] = useState(0);
  const [quizState, setQuizState] = useState(quiz);
  const [dirtyState, setDirtyState] = useState(false);

  const instructions = "Write what you think the 'console.log' output will be from the above script.\n\n"+
    "Remember, each 'console.log' prints on a new line.";

  function handleOutput(output) {
    setRunning(false);
    const outputText = output.reduce((prev, curr) => prev + "\n" + curr);
    const guessArray = guess.split(/\r?\n/);

    if (outputText.trim() !== guess.trim()) {
      setStatusStyle("wrong");
      let status = "Your answer was wrong. Try again!";
      let rights = 0;
      for (let nr in output) {
        if (output[nr] === guessArray[nr]) {
          if (rights === 0) {
            status += `\nBut line ${(parseInt(nr)+1)} was correct. You can do it! :)`;
          } else {
            status += `\nAnd line ${(parseInt(nr)+1)} was also correct.`;
          }
          rights++;
        } else {
          if (rights > 0) {
            status += `\nLine ${(parseInt(nr)+1)} is wrong. :/`;
          }
        }
        setStatus(status);
      }
    } else {
      const winText = `Your answer was correct! :)\n${isAtLastQuiz() ? "There are no more questions left. Good job!\n" : "You can now go to the next question\n"}`;
      setStatus(winText);
      setStatusStyle("correctEnd correct");
      setQuizDone();
    }

    setDirtyState(true);
  }

  function runCodeStart() {
    setRunning(true);
    setStatusStyle("");
    setStatus("Testing .... please wait");
  }

  function setQuizDone() {
    const newQuizState = [...quizState];
    newQuizState.find(q => q.id === currentId).done = true;
    setQuizState(newQuizState);
    unlockNextQuiz();
  }

  function isAtLastQuiz() {
    return quizState.find(q => q.id === (currentId + 1)) === undefined;
  }

  function unlockNextQuiz() {
    const newQuizState = [...quizState];
    const nextQuiz = newQuizState.find(q => q.id === (currentId + 1));
    if (nextQuiz) {
      nextQuiz.unlocked = true;
      localStorage.setItem("latestUnlockedId", nextQuiz.id);
      setQuizState(newQuizState);
    }
  }

  function quizClick(id) {
    if (running) return;
    saveQuestionState();
    clearFields();
    setCurrentId(parseInt(id));
  }

  function saveQuestionState() {
    localStorage.setItem(currentId+"guess", guess);
    localStorage.setItem(currentId+"status", status);
  }

  function clearFields() {
    setStatus("");
    setGuess("");
    setStatusStyle("");
  }

  function resetAllProgress() {
     if (window.confirm('Are you sure you want to reset all your progress?')) {
      clearFields();
      setQuizState(prevQuizzState => prevQuizzState.map(q => {
        if (q.id === 0) {
          return {...q, unlocked: true, done: false}
        } else {
          return {...q, unlocked: false, done: false}
        }
      }));
      setCurrentId(0);
      localStorage.clear();
    }
  }

  function currentIsDone() {
    return quizState.find(q => q.id === parseInt(currentId)).done;
  }

  function goToNextQuestionButton() {
    const id = localStorage.getItem("latestUnlockedId");
    quizClick(id);
  }

  function renderAdvanceLink() {
    if (currentIsDone() && !isAtLastQuiz()) {
      return <button onClick={goToNextQuestionButton} className="cursorPointer">Go to next question</button>;
    } if (currentIsDone() && isAtLastQuiz()) {
      return <div className="win"><img src={win} alt="win"></img></div>;
    } else {
      return <></>;
    }
  }

  useEffect(() => {
    if (dirtyState) {
      setDirtyState(false);
      saveQuestionState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyState])

  // Load previous guess entered
  useEffect(() => {
    const pGuess = localStorage.getItem(currentId+"guess");
    setGuess(pGuess !== null ? pGuess : "");

    const pStatus= localStorage.getItem(currentId+"status");
    setStatus(pStatus !== null ? pStatus : "");    
  }, [currentId]) // Only use effect when currentId changes

  // Effect on page load
  useEffect(() => {
    function updateUnlocked(latestId) {
      setCurrentId(latestId);
      // unlock all up to current
      let newQuizState = quizState.map(q => {
        const newQ = {...q};
        if (newQ.id < latestId) {
          newQ.done = true;
          newQ.unlocked = true;
        }
        if (newQ.id === latestId) {
          if (quizState[quizState.length - 1].id === newQ.id) {
            newQ.done = true;
          } else {
            newQ.done = false;
          }
          newQ.unlocked = true;
        }
        return newQ; 
      });
      setQuizState(newQuizState);
    }
    
    const latestId = localStorage.getItem("latestUnlockedId");
    if (latestId) {
      updateUnlocked(parseInt(latestId));

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <header><div className="content"><h1>JavaScript Quiz!</h1></div></header>

      <div className='row'>
        <div className='column content'>
          <h2>{quizState[currentId].name}</h2>
          <Code code={codes[currentId]}></Code>
          <h2>Your answer</h2>
          <textarea 
            placeholder={instructions} rows="10" 
            onChange={event => {
                setGuess(event.target.value);
              }} value={guess}></textarea>
          <RunCode disabled={running}
            name="Give answer" 
            code={codes[currentId]} 
            runCodeCallback={runCodeStart} 
            outputCallback={handleOutput}/>
          <pre id="status" className={statusStyle}>{status}</pre>
          {renderAdvanceLink()}
        </div>
        <div className='column column-small content'>
          <h2>Your progress</h2>
          <ul className="quizList">
            {
              quizState.map(q => {
                if (!q.unlocked) return <li key={q.name}></li>;
                let className = q.done ? "questionDone" : "";
                className += (q.id === currentId) ? " questionCurrent" : " question";
                return <li key={q.name} className={className} 
                  onClick={() => quizClick(q.id)}>{q.name}</li>
              })
            }
          </ul>
        </div>
      </div>

      <footer>
        <div>
          <div className='footer-main'><a href="https://github.com/kdorland/">Made by Kristian</a></div>
        </div> 
        <div className="pushRight">
          <span className="cursorPointer" onClick={resetAllProgress}>Reset your progress</span>
        </div> 
      </footer>
    </>
  );
}

export default App;
