import React from 'react';

function RunCode(props) {
   // Wrap console.log and render messages
  let myLog = [];

  async function delay(value) {
    return new Promise((resolve, reject) => {
      setTimeout(_ => {
            resolve();
          }, value);
    });
  }
  
  async function runCode() {
    props.runCodeCallback(); 
    myLog = [];
  
    // Run the code and save log
    global.addLog = function(args) {
      myLog.push(args);
      if (window.jsquiz_debug) {
        console.log(args);
      }
    }
    // eslint-disable-next-line no-new-func
    console.log("code", props.code);
    const fn = (new Function(props.code.replace(/console\.log/g, 'addLog'))).bind(this); // silly hack
    fn();

    // Hackish solution since questions are not built on promises
    await delay(3000);
    props.outputCallback(myLog);
  }

  return (
    <>
      <button className={props.disabled ? "disabledButton" : ""} onClick={runCode}>{props.name}</button>
    </>
  );
}

export default RunCode;
